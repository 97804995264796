import { Axios } from '@/utils/runtime_config';
import { Models } from '@mtap-smartcity/lib-api';

export const get = async (): Promise<Models.Auth.Groups.GroupsModel[]> => {
  const result = (await Axios.get<Models.Auth.Groups.GroupsModel[]>('/api/auth/groups')).data;
  return result;
};

export const getId = async (groupId: string): Promise<Models.Auth.Groups.GroupsModel> => {
  const result = (await Axios.get<Models.Auth.Groups.GroupsModel>(`/api/auth/groups/${groupId}`)).data;
  return result;
};

export const getIdRole = async (groupId: string): Promise<Models.Auth.Roles.RolesModel[]> => {
  const result = (await Axios.get<Models.Auth.Roles.RolesModel[]>(`/api/auth/groups/${groupId}/roles`)).data;
  return result;
};

export const post = async (payload: Models.Auth.Groups.GroupsModel): Promise<Models.Auth.Groups.GroupsModel> => {
  const result = (await Axios.post<Models.Auth.Groups.GroupsModel>('/api/auth/groups', payload)).data;
  return result;
};

export const putId = async (payload: Models.Auth.Groups.GroupsModel): Promise<Models.Auth.Groups.GroupsModel> => {
  const id = payload?.id;
  // delete payload.id
  const result = (await Axios.put<Models.Auth.Groups.GroupsModel>(`/api/auth/groups/${id}`, payload)).data;
  return result;
};

export const putIdModifyRoles = async (groupId: string, roles: Models.Auth.Roles.RolesModel[]): Promise<void> => {
  const result = (await Axios.put<void>(`/api/auth/groups/${groupId}/modify-roles`, roles)).data;
  return result;
};

export const deleteId = async (id: string): Promise<void> => {
  const result = (await Axios.delete<void>(`/api/auth/groups/${id}`)).data;
  return result;
};
