import { Axios } from '@/utils/runtime_config';
import { Models } from '@mtap-smartcity/lib-api';

export const get = async (): Promise<Models.Auth.Roles.RolesModel[]> => {
  const result = (await Axios.get<Models.Auth.Roles.RolesModel[]>('/api/auth/roles')).data;
  return result;
};

export const getId = async (roleId: string): Promise<Models.Auth.Roles.RolesModel> => {
  const result = (await Axios.get<Models.Auth.Roles.RolesModel>(`/api/auth/roles/${roleId}`)).data;
  return result;
};

export const post = async (payload: Models.Auth.Roles.RolesModel): Promise<Models.Auth.Roles.RolesModel> => {
  const result = (await Axios.post<Models.Auth.Roles.RolesModel>('/api/auth/users', payload)).data;
  return result;
};

export const deleteId = async (id: string): Promise<void> => {
  const result = (await Axios.delete<void>(`/api/auth/users/${id}`)).data;
  return result;
};
