import { ActionTree, Dispatch } from 'vuex';
import {
  AppState,
  AppAction,
  UserActionStatus,
} from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';
import getRuntimeConfig, { Axios } from '@/utils/runtime_config';
import { AnalyticsTabsTypes, CreatorTabsTypes } from '@/constants/main_card_inner_tabs';

export const userActionDispatcher = (dispatch: Dispatch, message: UserActionStatus['message'], status: UserActionStatus['status']): void => {
  dispatch(`app/${AppAction.SetUserActionStatus}`,
    {
      message,
      status
    }, { root: true });
};

export const actions: ActionTree<AppState, RootState> = {
  async [AppAction.SetRuntimeConfig]({ commit, state }): Promise<void> {
    const runtimeConfig = (await getRuntimeConfig()).data;
    commit(`${AppAction.SetRuntimeConfig}Handler`, { ...state.runtimeConfig, ...runtimeConfig });
  },
  [AppAction.SetUserActionStatus]({ commit, state }, payload: UserActionStatus): void {
    if (payload.status !== 'pending') {
      if (state.timeoutID) {
        clearTimeout(state.timeoutID);
      }
      const timeoutID = setTimeout(() => {
        commit(`${AppAction.SetUserActionStatus}Handler`, {
          message: '',
          status: 'success'
        });
      }, 4000);
      commit(`${AppAction.SetTimeoutID}Handler`, timeoutID);
    }
    commit(`${AppAction.SetUserActionStatus}Handler`, payload);
  },
  [AppAction.SetTimeoutID]({ commit }, payload: null | number): void {
    commit(`${AppAction.SetTimeoutID}Handler`, payload);
  },
  [AppAction.SetSelectedTab]({ commit }, tabName: null | string): void {
    commit(`${AppAction.SetSelectedTab}Handler`, tabName);
  },
  [AppAction.SetSelectedDevicesTab]({ commit }, tab: Models.Constants.DeviceType): void {
    commit(`${AppAction.SetSelectedDevicesTab}Handler`, tab);
  },
  [AppAction.SetSelectedAnalyticsTab]({ commit }, tab: AnalyticsTabsTypes): void {
    commit(`${AppAction.SetSelectedAnalyticsTab}Handler`, tab);
  },
  [AppAction.SetSelectedCreatorTab]({ commit }, tab: CreatorTabsTypes): void {
    commit(`${AppAction.SetSelectedCreatorTab}Handler`, tab);
  },
  [AppAction.SetMapSelectionEnabled]({ commit }, enabled: boolean): void {
    commit(`${AppAction.SetMapSelectionEnabled}Handler`, enabled);
  },
  [AppAction.ToggleCircuitActionsCard]({ commit }, payload: boolean): void {
    commit(`${AppAction.ToggleCircuitActionsCard}Handler`, payload);
  },
  [AppAction.SetMapFocusPoint]({ commit }, payload: { lat: number, lng: number } | null): void {
    commit(`${AppAction.SetMapFocusPoint}Handler`, payload);
  },
  [AppAction.SetTriggerHistoricals]({ commit }, payload: boolean): void {
    commit(`${AppAction.SetTriggerHistoricals}Handler`, payload);
  },
  [AppAction.SetTimeHistoricals]({ commit }, payload: number): void {
    commit(`${AppAction.SetTimeHistoricals}Handler`, payload);
  },
  [AppAction.SetAuthenticationHeader]({ getters }): void {
    const token = getters.GET_TOKEN;
    Axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  },
};
