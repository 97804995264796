import { ActionTree } from 'vuex';
import { DevicesAction, DevicesState } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';
import { userActionDispatcher } from '../app/actions';
import {
  fetchDevices,
  createDevice,
  updateDevice,
  addDeviceToCircuit,
  addDeviceToScenario,
  overwriteDeviceSettings,
  uploadCsvDevices,
  recommissionDevice,
  fetchDevice
} from '@/api/devices';

export const actions: ActionTree<DevicesState, RootState> = {
  [DevicesAction.FetchDevices](
    { commit },
    {
      unixTime = null,
      deviceType
    }: {
      unixTime: number | null,
      deviceType?: Models.Constants.DeviceType
    } = { unixTime: null }
  ): Promise<void> {
    return fetchDevices(unixTime, deviceType)
      .then((response) => commit(`${DevicesAction.FetchDevices}Handler`, response));
  },

  [DevicesAction.FetchDevice](
    { commit, dispatch },
    {
      device,
      unixTime = null
    }: {
      device: Models.Devices.Device
      unixTime: number | null,
    }
  ): Promise<void> {
    return fetchDevice(device, unixTime)
      .then((fetchedDevice) => {
        commit(`${DevicesAction.FetchDevice}Handler`, fetchedDevice);
        userActionDispatcher(dispatch, '', 'success');
      });
  },

  [DevicesAction.AddDevicePlaceholder](
    { commit },
    { type, coordinates }: { type: Models.Constants.DeviceType, coordinates?: google.maps.LatLngLiteral }
  ): void {
    const { lat, lng } = coordinates || { lat: null, lng: null };
    const parameters = {
      device: {
        latitude: lat,
        longitude: lng
      },
      controller: {},
      powerSupply: {}
    };
    const controllerType = (() => {
      if (['bridge', 'gateway'].includes(type)) {
        return 've';
      }
      if (['sensor', 'control-cabinet'].includes(type)) {
        return 'iothub';
      }
      if (['lamp'].includes(type)) {
        return '';
      }
      return null;
    })();
    const placeholder = {
      id: null,
      object_id: '',
      device_type: type,
      controller_type: controllerType,
      device_version: 1,
      overwrite_enabled: false,
      overwrite_duty: null,
      overwrite_end_date: null,
      parameters
    };
    commit(`${DevicesAction.AddDevicePlaceholder}Handler`, placeholder);
  },

  [DevicesAction.RemoveDevicePlaceholder]({ commit }, type: Models.Constants.DeviceType): void {
    commit(`${DevicesAction.RemoveDevicePlaceholder}Handler`, type);
  },

  [DevicesAction.CreateDevice]({ dispatch, commit }, device: Models.Devices.Device): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.creatingDevice'), 'pending');
    return createDevice(device)
      .then((item) => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${DevicesAction.CreateDevice}Handler`, item);
      })
      .catch(() => {
        dispatch(
          DevicesAction.RemoveDevicePlaceholder,
          device.device_type
        );
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [DevicesAction.UpdateDevice]({ dispatch, commit }, device: Models.Devices.Device): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.updatingDevice'), 'pending');
    return updateDevice(device)
      .then(() => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${DevicesAction.UpdateDevice}Handler`, {
          uuid: device.uuid,
          newDeviceData: device
        });
      })
      .catch((error) => {
        console.error(error);
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw error;
      });
  },

  [DevicesAction.RecommissionDevice]({ dispatch }, device: Models.Devices.Device): Promise<Models.Devices.Model> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.bondingDevice'), 'pending');
    return recommissionDevice(device)
      .catch((error) => {
        console.error(error);
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw error;
      });
  },

  [DevicesAction.SetSelectedMarkerId]({ commit }, id: number): void {
    commit(`${DevicesAction.SetSelectedMarkerId}Handler`, id);
  },

  [DevicesAction.SetSelectedId]({ commit }, id: number | null): void {
    commit(`${DevicesAction.SetSelectedId}Handler`, id);
  },

  [DevicesAction.SetSelectedDeviceUuid]({ commit }, uuid: string | null): void {
    commit(`${DevicesAction.SetSelectedDeviceUuid}Handler`, uuid);
  },

  [DevicesAction.SetInsidePolygonMarkers]({ commit }, payload: Array<Models.Devices.Device>): void {
    commit(`${DevicesAction.SetInsidePolygonMarkers}Handler`, payload);
  },

  [DevicesAction.AddDeviceToCircuit](
    { commit, dispatch },
    { uuid, circuitId, controllerType }:
    { uuid: string, circuitId: string | null, controllerType: Models.Constants.ControllerType }
  ): Promise<void> {
    if (circuitId) {
      userActionDispatcher(dispatch, (this as any).$app.$t('messages.addingDeviceToCircuit[0]'), 'pending');
    } else {
      userActionDispatcher(dispatch, (this as any).$app.$t('messages.addingDeviceToCircuit[1]'), 'pending');
    }
    return addDeviceToCircuit(uuid, circuitId, controllerType)
      .then(() => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${DevicesAction.AddDeviceToCircuit}Handler`, { uuid, circuitId });
      })
      .catch((error) => {
        console.error(error);
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [DevicesAction.AddDeviceToScenario](
    { dispatch, commit }, 
    {
      device,
      scenarioName,
      assignToDefault
    }: {
      device: Models.Devices.Device,
      scenarioName: string | null
      assignToDefault: boolean
    }
  ): Promise<void> {
    const message = assignToDefault ? 'messages.detachingDeviceFromScenario' : 'messages.assigningDeviceToScenario';
    userActionDispatcher(dispatch, (this as any).$app.$t(message, { scenarioName }), 'pending');
    return addDeviceToScenario(device)
      .then(() => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${DevicesAction.UpdateDevice}Handler`, {
          uuid: device.uuid,
          newDeviceData: device
        });
      })
      .catch((error) => {
        console.error(error);
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [DevicesAction.OverwriteDeviceSettings]({ commit }, device: Models.Devices.Device): Promise<Models.Devices.Device> {
    return overwriteDeviceSettings(device)
      .then(() => {
        commit(`${DevicesAction.UpdateDevice}Handler`, {
          uuid: device.uuid,
          newDeviceData: device
        });
        return device;
      })
      .catch((err) => {
        throw err;
      });
  },

  async [DevicesAction.UploadCsvFile]({ commit }, payload: any): Promise<void> {
    const { data } = await uploadCsvDevices(payload);
    commit(`${DevicesAction.UploadCsvFile}Handler`, data);
  }
};
