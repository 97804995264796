import { ActionTree } from 'vuex';
import { GroupsState, GroupsAction } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';
import { userActionDispatcher } from '../app/actions';
import {
  fetchGroups, createGroup, updateGroup, deleteGroup, addGroupToScenario, overwriteGroupSettings
} from '@/api/groups';
import { CircuitsGetter, CircuitsAction, CircuitsGetterType } from '../circuits/types';

export const actions: ActionTree<GroupsState, RootState> = {
  [GroupsAction.FetchGroups](
    { commit },
    { unixTime = null }: { unixTime: number | null } = { unixTime: null }
  ): Promise<void> {
    return fetchGroups(unixTime)
      .then((groups: Array<Models.Groups.Model>) => commit(`${GroupsAction.FetchGroups}Handler`, groups));
  },

  [GroupsAction.SetSelectedGroupID]({ commit }, id: number | null): void {
    commit(`${GroupsAction.SetSelectedGroupID}Handler`, id);
  },

  [GroupsAction.SetSelectedGroupUuid]({ commit }, uuid: string | null): void {
    commit(`${GroupsAction.SetSelectedGroupUuid}Handler`, uuid);
  },

  [GroupsAction.AddGroupPlaceholder]({ commit }): void {
    commit(`${GroupsAction.AddGroupPlaceholder}Handler`);
  },

  [GroupsAction.RemoveGroupPlaceholder]({ commit }): void {
    commit(`${GroupsAction.RemoveGroupPlaceholder}Handler`);
  },

  [GroupsAction.CreateGroup]({ commit, dispatch }, group: Models.Groups.Model): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.creatingGroup'), 'pending');
    return createGroup(group)
      .then(async (item) => {
        commit(`${GroupsAction.CreateGroup}Handler`, item);
        userActionDispatcher(dispatch, '', 'success');
      })
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  },

  [GroupsAction.UpdateGroup]({ dispatch, commit }, group: Models.Groups.Model): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.updatingGroup'), 'pending');
    return updateGroup(group)
      .then((item) => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${GroupsAction.UpdateGroup}Handler`, item);
      })
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  },

  [GroupsAction.DeleteGroup]({ dispatch, commit, rootGetters }, uuid: string): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.deletingGroup'), 'pending');
    return deleteGroup(uuid)
      .then(() => {
        const getGroupCircuits: CircuitsGetterType['GET_GROUP_CIRCUITS'] = (rootGetters as any)[`circuits/${CircuitsGetter.GetGroupCircuits}`];
        const groupCircuits = getGroupCircuits(uuid);
        groupCircuits.forEach((circuit) => {
          commit(`circuits/${CircuitsAction.UpdateCircuit}Handler`, {
            ...circuit,
            groupId: null,
          }, { root: true });
        });
        userActionDispatcher(dispatch, '', 'success');
        commit(`${GroupsAction.DeleteGroup}Handler`, uuid);
      })
      .catch(() => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [GroupsAction.AddGroupToScenario](
    { dispatch, commit }, 
    {
      group,
      scenarioName,
      assignToDefault
    }: {
      group: Models.Groups.Model,
      scenarioName: string | null
      assignToDefault: boolean
    }
  ): Promise<void> {
    const message = assignToDefault ? 'messages.detachingGroupFromScenario' : 'messages.assigningGroupToScenario';
    userActionDispatcher(dispatch, (this as any).$app.$t(message, { scenarioName }), 'pending');
    return addGroupToScenario(group)
      .then(() => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${GroupsAction.UpdateGroup}Handler`, group);
      })
      .catch(() => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [GroupsAction.OverwriteGroupSettings]({ dispatch, commit }, group: Models.Groups.Model): Promise<Models.Groups.Model> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.sendingOverwrite'), 'pending');
    return overwriteGroupSettings(group)
      .then(() => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${GroupsAction.UpdateGroup}Handler`, group);
        return group;
      })
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  }
};
