import { GetterTree } from 'vuex';
import { AdminState, UserGetter } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';

export const getters: GetterTree<AdminState, RootState> = {
  // [UserGetter.GetUser](state): Models.Bff.Auth.Users.Model {
  [UserGetter.GetUser](state): Models.Bff.Auth.Users.Model {
    return state.user;
  },
  [UserGetter.GetPermissions](state): any {
    return state.permissions;
  },
  [UserGetter.GetGroupRoles](state): (groupId: string) => Models.Auth.Roles.RolesModel[] | undefined {
    return (groupId) => state.groups.find((obj) => obj.id === groupId)?.roles;
  },
  // eslint-disable-next-line no-shadow
  [UserGetter.GetFilteredGroupRoles](state, getters): (groupId: string) => Models.Auth.Groups.GroupsModel[] {
    return (groupId) => getters[UserGetter.GetGroupRoles](groupId)!.filter(({ name }) => {
      if (name.includes('_read') || name.includes('_write') || name.includes('_delete')) {
        return true;
      }
      return false;
    });
  },
};
