import { ActionTree } from 'vuex';
import { TelemetryAction, TelemetryState } from './types';
import { RootState } from '@/store/types';
import {
  fetchDeviceTelemetry,
  fetchDeviceTelemetriesArray,
  fetchDeviceTelemetriesFromTo,
  fetchCircuitTelemetriesFromTo,
  fetchGroupTelemetriesFromTo,
  fetchEnergyUsedFromTo,
  fetchLastTelemetriesFrom,
  fetchDeviceKobize,
  fetchCircuitKobize,
  fetchGroupKobize
} from '@/api/telemetry';
import { Models } from '@mtap-smartcity/lib-api';

export const actions: ActionTree<TelemetryState, RootState> = {
  async [TelemetryAction.FetchDeviceTelemetry]({ commit }, { objectId }: {
    objectId: string
  }): Promise<void> {
    try {
      const response: Models.Telemetries.Model = await fetchDeviceTelemetry({ objectId });
      commit(`${TelemetryAction.FetchDeviceTelemetry}Handler`, response);
    } catch (error) {
      console.error(error.message);
      throw new Error(error);
    }
  },

  async [TelemetryAction.FetchLastTelemetriesFrom]({ commit }, queryParams: {
    attributes: string,
    timestampFrom: string
  }): Promise<void> {
    try {
      const response: Models.Telemetries.Model[] = await fetchLastTelemetriesFrom(queryParams);
      commit(`${TelemetryAction.FetchLastTelemetriesFrom}Handler`, response);
    } catch (error) {
      console.error(error.message);
      throw new Error(error);
    }
  },

  async [TelemetryAction.FetchDeviceTelemetriesArray]({ commit }, queryParams: {
    limit?: number,
    offset?: number,
    deviceId?: string | null,
    error?: number | number[]
  }): Promise<void> {
    try {
      const response: Models.Telemetries.Telemetry[] = await fetchDeviceTelemetriesArray(queryParams);
      return commit(`${TelemetryAction.FetchDeviceTelemetriesArray}Handler`, response);
    } catch (error) {
      console.error(error.message);
      throw new Error(error);
    }
  },

  async [TelemetryAction.FetchDeviceTelemetriesFromTo]({ commit }, queryParams: {
    timestampFrom?: string,
    timestampTo?: string,
    deviceId?: string | null
  }): Promise<void> {
    try {
      const response: Models.Telemetries.Telemetry[] = await fetchDeviceTelemetriesFromTo(queryParams);
      return commit(`${TelemetryAction.FetchDeviceTelemetriesFromTo}Handler`, response);
    } catch (error) {
      console.error(error.message);
      return commit(`${TelemetryAction.FetchDeviceTelemetriesFromTo}Handler`, null);
    }
  },

  async [TelemetryAction.FetchCircuitTelemetriesFromTo]({ commit }, {
    objectId,
    queryParams
  }: {
    objectId: string,
    queryParams: {
      startDate?: string,
      endDate?: string,
      timestamp_delta?: number
    }
  }): Promise<void> {
    try {
      const response: Models.Telemetries.SumOfTelemetry = await fetchCircuitTelemetriesFromTo(objectId, queryParams);
      return commit(`${TelemetryAction.FetchCircuitTelemetriesFromTo}Handler`, response);
    } catch (error) {
      console.error(error.message);
      return commit(`${TelemetryAction.FetchCircuitTelemetriesFromTo}Handler`, null);
    }
  },

  async [TelemetryAction.FetchGroupTelemetriesFromTo]({ commit }, {
    objectId,
    queryParams
  }: {
    objectId: string,
    queryParams: {
      startDate?: string,
      endDate?: string,
      timestamp_delta?: number
    }
  }): Promise<void> {
    try {
      const response: Models.Telemetries.SumOfTelemetry = await fetchGroupTelemetriesFromTo(objectId, queryParams);
      return commit(`${TelemetryAction.FetchGroupTelemetriesFromTo}Handler`, response);
    } catch (error) {
      console.error(error.message);
      return commit(`${TelemetryAction.FetchGroupTelemetriesFromTo}Handler`, null);
    }
  },

  async [TelemetryAction.FetchEnergyUsedFromTo]({ commit }, queryParams: {
    timestampFrom?: string,
    timestampTo?: string,
    deviceId?: string | null
  }): Promise<void> {
    try {
      const response: number = await fetchEnergyUsedFromTo(queryParams);
      commit(`${TelemetryAction.FetchEnergyUsedFromTo}Handler`, response);
    } catch (error) {
      console.error(error.message);
      throw new Error(error);
    }
  },

  async [TelemetryAction.FetchDeviceKobize]({ commit }, {
    device,
    queryParams
  }: {
    device: Models.Devices.Device,
    queryParams?: {
      pointInTime?: string,
      startDate?: string,
      endDate?: string
    }
  }): Promise<void> {
    try {
      const kobize: Models.Analytics.Kobize.Model = await fetchDeviceKobize(device, queryParams);
      return commit(`${TelemetryAction.FetchDeviceKobize}Handler`, kobize);
    } catch (error) {
      console.error(error.message);
      return commit(`${TelemetryAction.FetchDeviceKobize}Handler`, null);
    }
  },

  async [TelemetryAction.FetchCircuitKobize]({ commit }, {
    circuit,
    queryParams
  }: {
    circuit: Models.Circuits.Model,
    queryParams?: {
      startDate?: string,
      endDate?: string
    }
  }): Promise<void> {
    try {
      const sumOfKobize: Models.Analytics.Kobize.SumOfKobize = await fetchCircuitKobize(circuit, queryParams);
      return commit(`${TelemetryAction.FetchCircuitKobize}Handler`, sumOfKobize);
    } catch (error) {
      console.error(error.message);
      return commit(`${TelemetryAction.FetchCircuitKobize}Handler`, null);
    }
  },

  async [TelemetryAction.FetchGroupKobize]({ commit }, {
    group,
    queryParams
  }: {
    group: Models.Groups.Model,
    queryParams?: {
      startDate?: string,
      endDate?: string
    }
  }): Promise<void> {
    try {
      const sumOfKobize: Models.Analytics.Kobize.SumOfKobize = await fetchGroupKobize(group, queryParams);
      return commit(`${TelemetryAction.FetchGroupKobize}Handler`, sumOfKobize);
    } catch (error) {
      console.error(error.message);
      return commit(`${TelemetryAction.FetchGroupKobize}Handler`, null);
    }
  },

  [TelemetryAction.SetSelectedReading](
    { commit },
    { reading = null }: { reading: Models.Telemetries.Telemetry | null }
  ): void {
    return commit(`${TelemetryAction.SetSelectedReading}Handler`, reading);
  },
};
