


























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { AppGetter, AppState } from '@/store/modules/app/types';
import variables from '@/styles/abstracts/_variables.scss';
import TheNavDrawerFloatingLogo from './TheNavDrawerFloatingLogo/TheNavDrawerFloatingLogo.vue';
import TheNavDrawerLinks from './TheNavDrawerLinks/TheNavDrawerLinks.vue';
import TheNavDrawerProfileInfo from './TheNavDrawerProfileInfo/TheNavDrawerProfileInfo.vue';
import MapActionsBar from '../MapActionsBar.vue';
import LanguageSwitch from './LanguageSwitch/LanguageSwitch.vue';
import {
  AdminAction,
  AdminActionType,
  UserGetter,
  UserGetterType,
  AdminState
} from '@/store/modules/admin/types';
import { Models } from '@mtap-smartcity/lib-api';
import { getLoggedUserId } from '@/utils/helpers';

const admin = namespace('admin');
const app = namespace('app');

@Component({
  name: 'TheNavDrawer',
  components: {
    TheNavDrawerFloatingLogo,
    TheNavDrawerLinks,
    TheNavDrawerProfileInfo,
    MapActionsBar,
    LanguageSwitch
  }
})
/**
 * @vuese
 * @group The NavDrawer
 * Main navigation drawer
 */
export default class TheNavDrawer extends Vue {
  hover = false;

  renderFlag = '';

  token = '';

  keycloakRoles: Models.Auth.Roles.RolesModel[] = [];

  @admin.State
  user!: AdminState['user'];

  @app.State
  selectedTab!: AppState['selectedTab']

  @app.State
  mapSelectionEnabled!: AppState['mapSelectionEnabled']

  @app.State
  triggerHistoricals!: AppState['triggerHistoricals'];

  @admin.Action(AdminAction.SetUserPermissions)
  SetUserPermissions!: AdminActionType['SET_USER_PERMISSIONS'];

  @admin.Action(AdminAction.FetchGroups)
  FetchGroups!: AdminActionType['FETCH_GROUPS'];

  @admin.Action(AdminAction.FetchGroupRoles)
  FetchGroupRoles!: AdminActionType['FETCH_GROUP_ROLES'];

  @admin.Getter(UserGetter.GetFilteredGroupRoles)
  GetFilteredGroupRoles!: UserGetterType['GET_FILTERED_GROUP_ROLES'];

  @admin.Getter(UserGetter.GetGroupRoles)
  GetGroupRoles!: UserGetterType['GET_GROUP_ROLES'];

  get isExpanded() {
    // expand the drawer if the user has opened any of the drawer tabs (TheMainCard is displayed)
    // or when the user hovers over the drawer
    return this.selectedTab !== null || this.hover;
  }

  get drawerDimensions() {
    return variables;
  }

  get guestModeId() {
    if (this.triggerHistoricals) {
      return '3dc1280e-0c48-4111-8f01-518adf934653';
    }
    return null;
  }

  handleHover(expand: boolean) {
    // disable expand/collapse behavior if the user is currently creating a circuit on the map
    if (this.mapSelectionEnabled) return;
    this.hover = expand;
  }

  async fetchGroupRoles(groupId: string) {
    let guestModeVariable = groupId;
    if (this.guestModeId != null) {
      guestModeVariable = this.guestModeId;
    }
    try {
      await this.FetchGroupRoles(guestModeVariable);
      this.keycloakRoles = this.GetFilteredGroupRoles(guestModeVariable);
      this.SetUserPermissions(this.keycloakRoles);
    } catch (error) {
      // TODO display some information about error
      this.keycloakRoles = [];
      console.error(error);
    }
  }

  @Watch('renderFlag', { deep: true, immediate: true })
  async onChange() {
    const token = this.$store.getters[`app/${AppGetter.GetToken}`];
    const userId = getLoggedUserId(token);
    if (!userId) return;
    try {
      await this.$store.dispatch(`admin/${AdminAction.FetchUser}`, userId);
      await this.FetchGroupRoles(this.user.groupId![0].id as string);
      const user = this.$store.getters[`admin/${UserGetter.GetUser}`];
      if (!user.groupId) return;
      this.renderFlag = user.groupId[0].id as string;
      this.fetchGroupRoles(user.groupId[0].id as string);
    } catch (error) {
      console.error(error);
    }
  }

  @Watch('guestModeId', { deep: true, immediate: true })
  async onRerender() {
    const token = this.$store.getters[`app/${AppGetter.GetToken}`];
    const userId = getLoggedUserId(token);
    if (!userId) return;
    try {
      await this.$store.dispatch(`admin/${AdminAction.FetchUser}`, userId);
      const user = this.$store.getters[`admin/${UserGetter.GetUser}`];
      if (!user.groupId) return;
      this.renderFlag = user.groupId[0].id as string;
      this.fetchGroupRoles(user.groupId[0].id as string);
    } catch (error) {
      console.error(error);
    }
  }

  async beforeMount() {
    // @ts-ignore
    // await this.FetchGroupIdRoles(this.user.groupId);
    await this.FetchGroups();
  }
}
