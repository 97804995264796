import { RuntimeConfig } from '@/types/config';
import { Models } from '@mtap-smartcity/lib-api';
import { isControlCabinetTelemetry } from './type_check';

function isInAllowedDelay(telemetry: Models.Telemetries.Telemetry | null, runtimeConfig: RuntimeConfig | null) {
  if (!runtimeConfig) {
    return false;
  }

  const telemetryTimestamp = telemetry ? new Date(telemetry!.timestamp) : null;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let allowedDelay: number;
  if (!telemetry) return false;
  if (isControlCabinetTelemetry(telemetry)) {
    allowedDelay = runtimeConfig.ALLOWED_TELEMETRY_DELAY_FOR_CONTROL_CABINET;
  } else {
    allowedDelay = runtimeConfig.ALLOWED_TELEMETRY_DELAY;
  }

  return telemetryTimestamp !== null ? (Math.abs(Number(telemetryTimestamp) - Number(new Date())) <= allowedDelay) : false;
}

export { isInAllowedDelay };
