import { Models } from '@mtap-smartcity/lib-api';

interface ScenariosState {
  scenarios: Array<Models.Scenarios.Model>,
  selectedScenarioID: number | null,
  selectedScenarioUuid: string | null,
  scenarioElements: Array<Models.ScenarioElements.Model>,
  selectedScenarioElementEditID: number | null,
  selectedScenarioHasConnections: boolean | null
}

enum ScenariosGetter {
  GetScenario = 'GET_SCENARIO',
  GetScenarioById = 'GET_SCENARIO_BY_ID',
  GetScenarioElement = 'GET_SCENARIO_ELEMENT',
  GetScenarioElementById = 'GET_SCENARIO_ELEMENT_BY_ID'
}

type ScenariosGetterType = {
  [ScenariosGetter.GetScenario]: (uuid: string) => Models.Scenarios.Model | undefined,
  [ScenariosGetter.GetScenarioById]: (id: number) => Models.Scenarios.Model | undefined,
  [ScenariosGetter.GetScenarioElement]: (uuid: string) => (Models.ScenarioElements.Model | undefined),
  [ScenariosGetter.GetScenarioElementById]: (id: number) => (Models.ScenarioElements.Model | undefined)
}

enum ScenariosAction {
  FetchScenarios = 'FETCH_SCENARIOS',
  FetchScenario = 'FETCH_SCENARIO',
  AddScenarioPlaceholder = 'ADD_SCENARIO_PLACEHOLDER',
  RemoveScenarioPlaceholder = 'REMOVE_SCENARIO_PLACEHOLDER',
  CreateScenario = 'CREATE_SCENARIO',
  UpdateScenario = 'UPDATE_SCENARIO',
  DeleteScenario = 'DELETE_SCENARIO',
  SetScenarioAsDefault = 'SET_SCENARIO_AS_DEFAULT',
  SetSelectedScenarioID = 'SET_SELECTED_SCENARIO_ID',
  SetSelectedScenarioUuid = 'SET_SELECTED_SCENARIO_UUID',
  SetSelectedScenarioHasConnections = 'SET_SELECTED_SCENARIO_HAS_CONNECTIONS',
  UnsetPreviousDefaultScenarios = 'UNSET_PREVIOUS_DEFAULT_SCENARIOS',
  FetchScenarioElements = 'FETCH_SCENARIO_ELEMENTS',
  FetchScenarioElement = 'FETCH_SCENARIO_ELEMENT',
  FetchScenarioElementsByScenario = 'FETCH_SCENARIO_ELEMENTS_BY_SCENARIO',
  FetchScenarioElementsByGraph = 'FETCH_SCENARIO_ELEMENTS_BY_GRAPH',
  CreateScenarioElement = 'CREATE_SCENARIO_ELEMENT',
  UpdateScenarioElement = 'UPDATE_SCENARIO_ELEMENT',
  DeleteScenarioElement = 'DELETE_SCENARIO_ELEMENT',
  SetSelectedScenarioElementID = 'SET_SELECTED_SCENARIO_ELEMENT_ID',
  FetchSunriseSunsetRange = 'FETCH_SUNRISE_SUNSET_RANGE',
  UpdateScenarioElementsPriorities='UPDATE_SCENARIO_ELEMENTS_PRIORITIES',
  ClearStateScenarioElements = 'CLEAR_STATE_SCENARIO_ELEMENTS',
}

type ScenariosActionType = {
  [ScenariosAction.FetchScenarios]: (payload?: { unixTime: number | null }) => Promise<void>,
  [ScenariosAction.FetchScenario]: (payload?: { scenario: Models.Scenarios.Model, unixTime: number | null }) => Promise<void>,
  [ScenariosAction.AddScenarioPlaceholder]: () => void,
  [ScenariosAction.RemoveScenarioPlaceholder]: () => void,
  [ScenariosAction.CreateScenario]: (payload: Models.Scenarios.Model) => Promise<void>,
  [ScenariosAction.UpdateScenario]: (payload: Models.Scenarios.Model) => Promise<void>,
  [ScenariosAction.DeleteScenario]: (scenario: Models.Scenarios.Model) => Promise<void>,
  [ScenariosAction.SetScenarioAsDefault]: (scenario: Models.Scenarios.Model) => Promise<unknown>,
  [ScenariosAction.SetSelectedScenarioID]: (payload: number | null) => void,
  [ScenariosAction.SetSelectedScenarioUuid]: (payload: string | null) => void,
  [ScenariosAction.SetSelectedScenarioHasConnections]: (hasConnections: boolean | null) => void,
  [ScenariosAction.UnsetPreviousDefaultScenarios]: (scenario: Models.Scenarios.Model) => void,
  [ScenariosAction.FetchScenarioElements]: (payload?: { unixTime?: number | null }) => Promise<void> | void,
  [ScenariosAction.FetchScenarioElement]: (payload: { unixTime?: number | null, uuid: string }) => Promise<void> | void,
  [ScenariosAction.FetchScenarioElementsByScenario]: (payload: { unixTime?: number | null, uuid: string }) => Promise<void> | void,
  [ScenariosAction.FetchScenarioElementsByGraph]: (payload: { unixTime?: number, id: string }) => Promise<void> | void,
  [ScenariosAction.CreateScenarioElement]: (payload: Models.ScenarioElements.Model) => Promise<void>,
  [ScenariosAction.UpdateScenarioElement]: (payload: Models.ScenarioElements.Model) => Promise<void>,
  [ScenariosAction.DeleteScenarioElement]: (uuid: string) => Promise<void>,
  [ScenariosAction.SetSelectedScenarioElementID]: (scenarioElementId: number | null) => void,
  [ScenariosAction.UpdateScenarioElementsPriorities]: (scenarioElementsPriorities: Models.ScenarioElements.Priority[]) => Promise<Models.ScenarioElements.Priority>;
  [ScenariosAction.ClearStateScenarioElements]: () => void;
}

export {
  ScenariosState,
  ScenariosGetter,
  ScenariosGetterType,
  ScenariosAction,
  ScenariosActionType
};
