import { ActionTree } from 'vuex';
import { ScenariosState, ScenariosAction, ScenariosGetter } from './types';
import { RootState } from '@/store/types';
import { userActionDispatcher } from '../app/actions';
import { Models } from '@mtap-smartcity/lib-api';
import {
  fetchScenarios,
  fetchScenario,
  createScenario,
  updateScenario,
  deleteScenario,
  setScenarioAsDefault,
  fetchScenarioElements,
  fetchScenarioElement,
  fetchScenarioElementsByScenario,
  fetchScenarioElementsByGraph,
  createScenarioElement,
  deleteScenarioElement,
  updateScenarioElement,
  updateScenarioElementsPriorities
} from '@/api/scenarios';

export const actions: ActionTree<ScenariosState, RootState> = {
  [ScenariosAction.FetchScenarios](
    { commit },
    { unixTime = null }: { unixTime: number | null } = { unixTime: null }
  ): Promise<void> {
    return fetchScenarios(unixTime)
      .then((response) => commit(`${ScenariosAction.FetchScenarios}Handler`, response));
  },

  // WARNING COMMENT
  // The following endpoint does not return the complete scenario object - do not use unless the endpoint is fixed
  [ScenariosAction.FetchScenario](
    { commit, dispatch },
    { uuid, unixTime = null }: { uuid: string; unixTime?: number | null }
  ): Promise<void> {
    return fetchScenario(uuid, unixTime)
      .then((scenario) => commit(`${ScenariosAction.FetchScenario}Handler`, scenario))
      .catch((error) => {
        console.error(error);
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw error;
      });
  },

  [ScenariosAction.AddScenarioPlaceholder]({ commit }): void {
    commit(`${ScenariosAction.AddScenarioPlaceholder}Handler`);
  },

  [ScenariosAction.RemoveScenarioPlaceholder]({ commit }): void {
    commit(`${ScenariosAction.RemoveScenarioPlaceholder}Handler`);
  },

  [ScenariosAction.CreateScenario](
    { commit, dispatch },
    scenario: Models.Scenarios.Model
  ): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.creatingScenario'), 'pending');
    return createScenario(scenario)
      .then((item) => {
        commit(`${ScenariosAction.CreateScenario}Handler`, item);
        commit(`${ScenariosAction.SetSelectedScenarioID}Handler`, item.id);
        commit(`${ScenariosAction.SetSelectedScenarioUuid}Handler`, item.uuid);
        userActionDispatcher(dispatch, '', 'success');
      })
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  },

  [ScenariosAction.UpdateScenario](
    { dispatch, commit },
    scenario: Models.Scenarios.Model
  ): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.updatingScenario'), 'pending');
    return updateScenario(scenario)
      .then(async (item) => {
        if (item) { // TODO
          userActionDispatcher(dispatch, '', 'success');
          commit(`${ScenariosAction.UpdateScenario}Handler`, item);
        } else {
          userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        }
      })
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  },

  [ScenariosAction.DeleteScenario]({ commit, dispatch }, scenario: Models.Scenarios.Model): Promise<void> {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.deletingScenario'), 'pending');
    return deleteScenario(scenario.uuid!)
      .then(() => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${ScenariosAction.DeleteScenario}Handler`, scenario.uuid);
      })
      .catch(() => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [ScenariosAction.SetScenarioAsDefault]({ dispatch }, scenario: Models.Scenarios.Model): Promise<unknown> {
    return setScenarioAsDefault(scenario.uuid!)
      .then(() => dispatch(`${[ScenariosAction.FetchScenarios]}`))
      .catch(() => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [ScenariosAction.SetSelectedScenarioID]({ commit }, payload: number): void {
    commit(`${ScenariosAction.SetSelectedScenarioID}Handler`, payload);
  },

  [ScenariosAction.SetSelectedScenarioUuid]({ commit }, payload: string): void {
    commit(`${ScenariosAction.SetSelectedScenarioUuid}Handler`, payload);
  },

  [ScenariosAction.SetSelectedScenarioHasConnections]({ commit }, hasConnections: boolean | null): void {
    commit(`${ScenariosAction.SetSelectedScenarioHasConnections}Handler`, hasConnections);
  },

  [ScenariosAction.UnsetPreviousDefaultScenarios]({ commit }, newDefault: Models.Scenarios.Model): void {
    commit(`${ScenariosAction.UnsetPreviousDefaultScenarios}Handler`, newDefault);
  },

  [ScenariosAction.FetchScenarioElements](
    { commit, dispatch },
    { unixTime = null }: { unixTime: number | null } = { unixTime: null }
  ): Promise<void> | void {
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.fetchingRules'), 'pending');
    return fetchScenarioElements(unixTime)
      .then((response) => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${ScenariosAction.FetchScenarioElements}Handler`, response);
      });
  },

  [ScenariosAction.FetchScenarioElement](
    { commit, dispatch },
    { unixTime = null, uuid }: { unixTime?: number | null, uuid: string }
  ): Promise<void> | void {
    if (!uuid) {
      return commit(`${ScenariosAction.FetchScenarioElements}Handler`, []);
    }
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.fetchingRules'), 'pending');
    return fetchScenarioElement(unixTime, uuid)
      .then((response) => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${ScenariosAction.FetchScenarioElement}Handler`, response);
      });
  },

  [ScenariosAction.FetchScenarioElementsByScenario](
    { commit, dispatch },
    { unixTime = null, uuid }: { unixTime?: number | null, uuid: string}
  ): Promise<void> | void {
    if (!uuid) {
      return commit(`${ScenariosAction.FetchScenarioElements}Handler`, []);
    }
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.fetchingRules'), 'pending');
    return fetchScenarioElementsByScenario(unixTime, uuid)
      .then((items) => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${ScenariosAction.FetchScenarioElements}Handler`, items);
      });
  },

  [ScenariosAction.FetchScenarioElementsByGraph](
    { commit, dispatch },
    { unixTime, id }: { unixTime: number, id: string}
  ): Promise<void> | void {
    if (!id) {
      return commit(`${ScenariosAction.FetchScenarioElements}Handler`, []);
    }
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.fetchingRules'), 'pending');
    return fetchScenarioElementsByGraph(unixTime, id)
      .then((response) => {
        userActionDispatcher(dispatch, '', 'success');
        commit(`${ScenariosAction.FetchScenarioElements}Handler`, response);
      });
  },

  [ScenariosAction.CreateScenarioElement]({
    commit, dispatch, getters, state
  }, scenarioElement: Models.ScenarioElements.Model): Promise<void> {
    const scenario: Models.Scenarios.Model = getters[ScenariosGetter.GetScenario](state.selectedScenarioID);
    userActionDispatcher(dispatch, (this as any).$app.$t('messages.creatingRule'), 'pending');
    return createScenarioElement(scenarioElement)
      .then((item) => {
        if (scenario.scenario_element_uuids) {
          scenario.scenario_element_uuids.push(item.uuid!);
        } else {
          scenario.scenario_element_uuids = [item.uuid!];
        }
        dispatch(ScenariosAction.UpdateScenario, scenario)
          .then(() => {
            commit(`${ScenariosAction.CreateScenarioElement}Handler`, item); // ??
            userActionDispatcher(dispatch, '', 'success');
          })
          .catch(() => {
            userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
          });
      })
      .catch(() => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
      });
  },

  [ScenariosAction.DeleteScenarioElement]({ commit }, uuid: string): Promise<void> {
    return deleteScenarioElement(uuid)
      .then(() => commit(`${ScenariosAction.DeleteScenarioElement}Handler`, uuid));
  },

  [ScenariosAction.UpdateScenarioElement](
    { dispatch },
    scenarioElement: Models.ScenarioElements.Model
  ): Promise<Models.ScenarioElements.Model> {
    return updateScenarioElement(scenarioElement)
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
    // ITEM IN THE RESPONSE DOES NOT HAVE REQUIRED STRUCTURE - MISSING FEW PROPERTIES
    // .then((item) => commit(`${ScenariosAction.UpdateScenarioElement}Handler`, item));
  },

  [ScenariosAction.SetSelectedScenarioElementID]({ commit }, scenarioElementID: number): void {
    commit(`${ScenariosAction.SetSelectedScenarioElementID}Handler`, scenarioElementID);
  },

  [ScenariosAction.UpdateScenarioElementsPriorities](
    { dispatch },
    scenarioElementsPriorities: Models.ScenarioElements.Priority[]
  ): Promise<Models.ScenarioElements.Priority> {
    return updateScenarioElementsPriorities(scenarioElementsPriorities)
      .catch((err) => {
        userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
        throw err;
      });
  },

  [ScenariosAction.ClearStateScenarioElements](
    { commit },
  ): void {
    commit(`${ScenariosAction.ClearStateScenarioElements}Handler`);
  },

};
