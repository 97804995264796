import { GetterTree } from 'vuex';
import { TelemetryState, TelemetryGetter } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';

export const getters: GetterTree<TelemetryState, RootState> = {
  [TelemetryGetter.GetLastTelemetry](state): (
    objectId: string,
    deviceType: Models.Constants.DeviceType
  ) => Models.Telemetries.Telemetry | null {
    return (objectId: string, deviceType: Models.Constants.DeviceType) => {
      const deviceTelemetry = state.lastTelemetries.find((reading: Models.Telemetries.Telemetry) => (
        reading.object_id === objectId
        && reading.device_type === deviceType
      ));
      if (!deviceTelemetry) return null;
      return deviceTelemetry;
    };
  },
};
