import Fetch from '@/utils/fetch';
import { Models } from '@mtap-smartcity/lib-api';

const fetchGroups = (unixTime) => Fetch<Models.Groups.Model[]>(`/api/groups?pointInTime=${unixTime}`, {});

const createGroup = (group: Models.Groups.Model) => {
  const newGroup = { ...group };
  delete newGroup.id; // removing placeholder's id

  return Fetch<Models.Groups.Model>('/api/groups', {
    method: 'POST',
    body: newGroup
  });
};

const updateGroup = (group: Models.Groups.Model) => Fetch<Models.Groups.Model>(`/api/groups/${group.uuid}`, {
  method: 'PUT',
  body: group,
});

const deleteGroup = (uuid: string) => Fetch(`/api/groups/${uuid}`, {
  method: 'DELETE'
});

const addGroupToScenario = (group: Models.Groups.Model) => Fetch(`/api/groups/${group.uuid}/scenario`, {
  method: 'PUT',
  body: group,
});

const overwriteGroupSettings = (group: Models.Groups.Model) => Fetch(`/api/groups/${group.uuid}/overwrite`, {
  method: 'PUT',
  body: group,
});

export {
  fetchGroups,
  createGroup,
  updateGroup,
  deleteGroup,
  addGroupToScenario,
  overwriteGroupSettings
};
