import { Models } from '@mtap-smartcity/lib-api';

interface GraphsState {
  graphs: Array<Models.Graphs.Model>,
}

enum GraphsGetter {
  GetGraph = 'GET_GRAPH'
}

type GraphsGetterType = {
  [GraphsGetter.GetGraph]: (uuid: string) => Models.Graphs.Model | undefined
}

enum GraphsAction {
  FetchGraphs = 'FETCH_GRAPHS',
  CreateGraph = 'CREATE_GRAPH',
  UpdateGraph = 'UPDATE_GRAPH',
  DeleteGraph = 'DELETE_GRAPH'
}

type GraphsActionType = {
  [GraphsAction.FetchGraphs]: (payload?: { unixTime: number }) => Promise<void>,
  [GraphsAction.CreateGraph]: (graph: Models.Graphs.Model) => Promise<Models.Graphs.Model | void>,
  [GraphsAction.UpdateGraph]: (graph: Models.Graphs.Model) => Promise<void>,
  [GraphsAction.DeleteGraph]: (uuid: string) => Promise<void>,
}

export {
  GraphsState,
  GraphsGetter,
  GraphsGetterType,
  GraphsAction,
  GraphsActionType
};
