import { GetterTree } from 'vuex';
import { DevicesState, DevicesGetter } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';

export const getters: GetterTree<DevicesState, RootState> = {
  [DevicesGetter.GetDevice](state): (uuid: string, deviceType?: Models.Constants.DeviceType) => Models.Devices.Device | undefined {
    return (uuid: string, deviceType: Models.Constants.DeviceType = 'lamp') => {
      if (deviceType === 'lamp') {
        return state.devices.find((m) => m.uuid === uuid);
      }
      return state.devices.find((d) => d.uuid === uuid);
    };
  },

  [DevicesGetter.GetDeviceById](state): (id: number, deviceType?: Models.Constants.DeviceType) => Models.Devices.Device | undefined {
    return (id: number, deviceType: Models.Constants.DeviceType = 'lamp') => {
      if (deviceType === 'lamp') {
        return state.devices.find((m) => m.id === id);
      }
      return state.devices.find((d) => d.id === id);
    };
  },

  [DevicesGetter.GetDeviceByObjectId](state): (objectId: string, deviceType?: Models.Constants.DeviceType) => Models.Devices.Device | undefined {
    return (objectId: string, deviceType: Models.Constants.DeviceType = 'lamp') => {
      if (deviceType === 'lamp') {
        return state.devices.find((m) => m.object_id === objectId);
      }
      return state.devices.find((d) => d.object_id === objectId);
    };
  },

  [DevicesGetter.GetSelectedMarker](state): Models.Devices.Device | null {
    if (state.selectedMarkerId) {
      const selectedMarker = state.devices.find((marker) => marker.id === state.selectedMarkerId);
      return selectedMarker as Models.Devices.Device;
    }
    return null;
  },

  [DevicesGetter.GetCircuitDevices](state): (circuitId: string) => Array<Models.Devices.Device> {
    return (circuitId) => state.devices.filter((m) => m.circuit_id === circuitId);
  },

  [DevicesGetter.GetCsvResult](state): FormData {
    return state.uploadCsvFile;
  },

};
