import { Models } from '@mtap-smartcity/lib-api';
import { AnalyticsTabsTypes, CreatorTabsTypes } from '@/constants/main_card_inner_tabs';
import { TranslateResult } from 'vue-i18n';
import { RuntimeConfig } from '@/types/config';

enum DrawerTab {
  Users,
  Devices,
  Reports,
  Creator,
  Planning,
  Optimization,
  Analytics,
  Archive,
  Submissions
}

interface UserActionStatus {
  message: TranslateResult,
  status: 'pending' | 'success' | 'error'
}

interface AppState {
  runtimeConfig: RuntimeConfig | null;
  userActionStatus: UserActionStatus;
  timeoutID: null | number;
  selectedTab: DrawerTab | null;
  selectedDevicesTab: Models.Constants.DeviceType;
  selectedAnalyticsTab: AnalyticsTabsTypes;
  selectedCreatorTab: CreatorTabsTypes;
  mapSelectionEnabled: boolean;
  showCircuitActionsCard: boolean;
  mapFocusPoint: google.maps.LatLngLiteral;
  triggerHistoricals: boolean;
  timeHistoricals: number;
  token: string;
  refreshToken: string;
  tokenExpire: number;
  refreshTokenExpire: number;
}

enum AppGetter {
  GetToken = 'GET_TOKEN',
  GetRefreshToken = 'GET_REFRESH_TOKEN',
  GetRuntimeConfig = 'GET_RUNTIME_CONFIG'
}

type AppGetterType = {
  [AppGetter.GetToken]: string,
  [AppGetter.GetRefreshToken]: string
  [AppGetter.GetRuntimeConfig]: RuntimeConfig | null
}

enum AppAction {
  SetRuntimeConfig = 'SET_RUNTIME_CONFIG',
  SetUserActionStatus = 'SET_USER_ACTION_STATUS',
  SetTimeoutID = 'SET_TIMEOUT_ID',
  SetSelectedTab = 'SET_SELECTED_TAB',
  SetSelectedDevicesTab = 'SET_SELECTED_DEVICES_TAB',
  SetSelectedAnalyticsTab = 'SET_SELECTED_ANALYTICS_TAB',
  SetSelectedCreatorTab = 'SET_SELECTED_CREATOR_TAB',
  SetMapSelectionEnabled = 'SET_MAP_SELECTION_ENABLED',
  ToggleCircuitActionsCard = 'TOGGLE_CIRCUIT_ACTIONS_CARD',
  SetMapFocusPoint = 'SET_MAP_FOCUS_POINT',
  SetTriggerHistoricals = 'SET_TRIGGER_HISTORICALS',
  SetTimeHistoricals = 'SET_TIME_HISTORICALS',
  SetAuthenticationHeader = 'SET_AUTHENTICATION_HEADER',
}

type AppActionType = {
  [AppAction.SetRuntimeConfig]: () => void,
  [AppAction.SetUserActionStatus]: (payload: UserActionStatus) => void,
  [AppAction.SetTimeoutID]: (payload: null | number) => void,
  [AppAction.SetSelectedTab]: (payload: DrawerTab | null) => void,
  [AppAction.SetSelectedDevicesTab]: (payload: Models.Constants.DeviceType) => void,
  [AppAction.SetSelectedAnalyticsTab]: (payload: AnalyticsTabsTypes) => void,
  [AppAction.SetSelectedCreatorTab]: (payload: CreatorTabsTypes) => void,
  [AppAction.SetMapSelectionEnabled]: (payload: boolean) => void,
  [AppAction.ToggleCircuitActionsCard]: (payload: boolean) => void,
  [AppAction.SetMapFocusPoint]: (payload: { lat: number, lng: number } | null) => void,
  [AppAction.SetTriggerHistoricals]: (payload: boolean) => void,
  [AppAction.SetTimeHistoricals]: (payload: number) => void,
  [AppAction.SetAuthenticationHeader]: () => void
}

export {
  DrawerTab,
  UserActionStatus,
  AppState,
  AppAction,
  AppActionType,
  AppGetterType,
  AppGetter
};
