import { GetterTree } from 'vuex';
import { GraphsState, GraphsGetter } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';

export const getters: GetterTree<GraphsState, RootState> = {
  [GraphsGetter.GetGraph](state): (uuid: string) => (Models.Graphs.Model | undefined) {
    return (uuid) => state.graphs.find((g) => g.uuid === uuid);
  }
};
