import Fetch from '@/utils/fetch';
import { Axios } from '@/utils/runtime_config';
import { Models } from '@mtap-smartcity/lib-api';

const fetchDevices = (unixTime?: number | null, deviceType?: Models.Constants.DeviceType): Promise<Models.Devices.Model[]> => {
  if (deviceType !== undefined) {
    return Fetch<Models.Devices.Model[]>(`/api/devices?type=${deviceType}&pointInTime=${unixTime}`, {});
  }
  return Fetch<Models.Devices.Model[]>(`/api/devices?pointInTime=${unixTime}`, {});
};

const fetchDevice = (
  device: Models.Devices.Model,
  unixTime?: number | null
): Promise<Models.Devices.Device> => Fetch<Models.Devices.Device>(
  `/api/devices/${device.uuid}?pointInTime=${unixTime}`, {}
);

const createDevice = (device: Models.Devices.Model) => {
  const newDevice = { ...device };
  delete newDevice.id; // removing placeholder's id
  return Fetch<Models.Devices.Model>('/api/devices', {
    method: 'POST',
    body: newDevice
  });
};

const updateDevice = (device: Models.Devices.Model) => Fetch<Models.Devices.Model>(`/api/devices/${device.uuid}`, {
  method: 'PUT',
  body: device,
});

const recommissionDevice = (device: Models.Devices.Model) => Fetch<Models.Devices.Model>(`/api/devices/${device.uuid}/iqrf/recommissioning`, {
  method: 'PUT',
});

const addDeviceToCircuit = (uuid: string, circuitId: string | null, controllerType: Models.Constants.ControllerType) => Fetch(`/api/devices/${uuid}/circuit`, {
  method: 'PUT',
  body: {
    circuit_id: circuitId,
    controller_type: controllerType
  },
});

const addDeviceToScenario = (device: Models.Devices.Model) => Fetch(`/api/devices/${device.uuid}/scenario`, {
  method: 'PUT',
  body: device
});

const overwriteDeviceSettings = (device: Models.Devices.Model) => Fetch(`/api/devices/${device.uuid}/overwrite`, {
  method: 'PUT',
  body: device
});

const uploadCsvDevices = async (devices: any) => {
  const result = await Axios.post('/api/devices/csv', devices);
  return result;
};

export {
  uploadCsvDevices,
  fetchDevices,
  fetchDevice,
  createDevice,
  updateDevice,
  recommissionDevice,
  addDeviceToCircuit,
  addDeviceToScenario,
  overwriteDeviceSettings
};
