/* eslint-disable no-param-reassign */
import { MutationTree } from 'vuex';
import { ScenariosState, ScenariosAction } from './types';
import { Models } from '@mtap-smartcity/lib-api';

export const mutations: MutationTree<ScenariosState> = {
  [`${ScenariosAction.FetchScenarios}Handler`](state, scenarios: Array<Models.Scenarios.Model>): void {
    state.scenarios = scenarios;
  },

  [`${ScenariosAction.FetchScenario}Handler`](state, scenario: Models.Scenarios.Model): void {
    const index = state.scenarios.findIndex((s) => s.uuid === scenario.uuid);
    if (index >= 0) state.scenarios.splice(index, 1, scenario);
    else state.scenarios.push(scenario);
  },

  [`${ScenariosAction.AddScenarioPlaceholder}Handler`](state): void {
    const scenarioPlaceholder = {
      id: undefined, name: '', is_default: false, scenario_element_uuids: []
    };
    if (state.scenarios.length === 0) {
      scenarioPlaceholder.is_default = true;
    }
    state.scenarios = [scenarioPlaceholder, ...state.scenarios];
  },

  [`${ScenariosAction.RemoveScenarioPlaceholder}Handler`](state): void {
    state.scenarios = state.scenarios.filter((obj) => !!obj.id);
  },

  [`${ScenariosAction.CreateScenario}Handler`](state, scenario: Models.Scenarios.Model): void {
    state.scenarios.push(scenario);
  },

  [`${ScenariosAction.UpdateScenario}Handler`](state, update: Models.Scenarios.Model): void {
    const scenarioIndex = state.scenarios.findIndex((scenario) => scenario.uuid === update.uuid);
    state.scenarios.splice(scenarioIndex, 1, update);
  },

  [`${ScenariosAction.UnsetPreviousDefaultScenarios}Handler`](state, newDefault: Models.Scenarios.Model): void {
    const scenarioToBeModified = state.scenarios.find((scenario) => scenario.uuid === newDefault.uuid);
    if (!scenarioToBeModified?.is_default) {
      // make assumption that in the database 'update' is the only scenario with is_default set to true
      // and update all scenarios in the store with is_default: true so that assumption is valid also for the store scenarios
      const defaultScenarios = state.scenarios.filter((scenario) => scenario.is_default);
      // eslint-disable-next-line no-return-assign
      defaultScenarios.map((scenario: Models.Scenarios.Model) => scenario!.is_default = false);
    }
  },

  [`${ScenariosAction.DeleteScenario}Handler`](state, uuid: string): void {
    state.scenarios = state.scenarios.filter((scenario) => scenario.uuid !== uuid);
  },

  [`${ScenariosAction.SetSelectedScenarioID}Handler`](state, payload): void {
    state.selectedScenarioID = payload;
  },

  [`${ScenariosAction.SetSelectedScenarioUuid}Handler`](state, payload): void {
    state.selectedScenarioUuid = payload;
  },

  [`${ScenariosAction.SetSelectedScenarioHasConnections}Handler`](state, hasConnections: boolean | null): void {
    state.selectedScenarioHasConnections = hasConnections;
  },

  [`${ScenariosAction.FetchScenarioElements}Handler`](state, scenarioElements: Array<Models.ScenarioElements.Model>): void {
    state.scenarioElements = scenarioElements.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
  },

  [`${ScenariosAction.FetchScenarioElement}Handler`](state, scenarioElement: Models.ScenarioElements.Model): void {
    const index = state.scenarioElements.findIndex((element) => element.uuid === scenarioElement.uuid);
    if (index >= 0) {
      state.scenarioElements.splice(index, 1, scenarioElement);
    } else {
      state.scenarioElements.unshift(scenarioElement);
    }
    state.scenarioElements.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
  },

  [`${ScenariosAction.CreateScenarioElement}Handler`](state, scenarioElement: Models.ScenarioElements.Model): void {
    state.scenarioElements.unshift(scenarioElement);
  },

  [`${ScenariosAction.DeleteScenarioElement}Handler`](state, uuid: string): void {
    state.scenarioElements = state.scenarioElements.filter((scenarioElement) => scenarioElement.uuid !== uuid);
  },

  [`${ScenariosAction.SetSelectedScenarioElementID}Handler`](state, scenarioElementId: number): void {
    state.selectedScenarioElementEditID = scenarioElementId;
  },

  [`${ScenariosAction.ClearStateScenarioElements}Handler`](state): void {
    state.scenarioElements = [];
  }
};
