import { MutationTree } from 'vuex';
import { DevicesState, DevicesAction } from './types';
import { Models } from '@mtap-smartcity/lib-api';

export const mutations: MutationTree<DevicesState> = {
  [`${DevicesAction.FetchDevices}Handler`](state, devices: Array<Models.Devices.Device>): void {
    state.devices = devices;
  },

  [`${DevicesAction.FetchDevice}Handler`](state, fetchedDevice: Models.Devices.Device): void {
    const index = state.devices.findIndex((device) => device.uuid === fetchedDevice.uuid);
    if (index >= 0) state.devices.splice(index, 1, fetchedDevice);
    else state.devices.push(fetchedDevice);
  },

  [`${DevicesAction.AddDevicePlaceholder}Handler`](state, placeholderDevice: Models.Devices.Device): void {
    state.devices = [placeholderDevice, ...state.devices];
  },

  [`${DevicesAction.RemoveDevicePlaceholder}Handler`](state): void {
    const placeholderDeviceIndex = state.devices.findIndex((d) => !d.id);
    if (placeholderDeviceIndex !== -1) {
      state.devices.splice(placeholderDeviceIndex, 1);
    }
  },

  [`${DevicesAction.CreateDevice}Handler`](state, device: Models.Devices.Device): void {
    state.devices.push(device);
  },

  [`${DevicesAction.SetInsidePolygonMarkers}Handler`](state, payload: Array<Models.Devices.Device>) {
    state.insidePolygonMarkers = payload;
  },

  [`${DevicesAction.AddDeviceToCircuit}Handler`](state, { uuid, circuitId }: { uuid: string, circuitId: string | null }): void {
    const updatedMarkerIndex = state.devices.findIndex((marker) => marker.uuid === uuid);
    const updatedMarker = { ...state.devices[updatedMarkerIndex], circuit_id: circuitId };
    state.devices.splice(updatedMarkerIndex, 1, updatedMarker);
  },

  [`${DevicesAction.SetSelectedMarkerId}Handler`](state, payload: number | null): void {
    state.selectedMarkerId = payload;
  },

  [`${DevicesAction.SetSelectedId}Handler`](state, payload: number | null): void {
    state.selectedId = payload;
  },

  [`${DevicesAction.SetSelectedDeviceUuid}Handler`](state, payload: string | null): void {
    state.selectedDeviceUuid = payload;
  },

  [`${DevicesAction.UpdateDevice}Handler`](state, { uuid, newDeviceData }: { uuid: string, newDeviceData: Models.Devices.Device }): void {
    const index = state.devices.findIndex((element) => element.uuid === uuid);
    state.devices.splice(index, 1, newDeviceData);
  },

  [`${DevicesAction.UploadCsvFile}Handler`](state, payload: any): void {
    state.uploadCsvFile = payload;
  },
};
