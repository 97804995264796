import { Axios } from '@/utils/runtime_config';
import { Models } from '@mtap-smartcity/lib-api';

export const get = async (): Promise<Models.Bff.Auth.Users.Model[]> => {
  const result = (await Axios.get<Models.Bff.Auth.Users.Model[]>('/api/bff/auth/users')).data;
  return result;
};

export const getId = async (userId: string): Promise<Models.Bff.Auth.Users.Model> => {
  const result = (await Axios.get<Models.Bff.Auth.Users.Model>(`/api/bff/auth/users/${userId}`)).data;
  return result;
};

export const post = async (payload: Models.Bff.Auth.Users.Model): Promise<Models.Bff.Auth.Users.Model> => {
  const result = (await Axios.post<Models.Bff.Auth.Users.Model>('/api/bff/auth/users', payload)).data;
  return result;
};

export const putId = async (payload: Models.Bff.Auth.Users.Model): Promise<Models.Bff.Auth.Users.Model> => {
  const id = payload?.id;
  // delete payload.id
  const result = (await Axios.put<Models.Bff.Auth.Users.Model>(`/api/bff/auth/users/${id}`, payload)).data;
  return result;
};
