



















































import { AnalyticsTabs } from '@/constants/main_card_inner_tabs';
// import { AdminState, UserGetter, UserGetterType } from '@/store/modules/admin/types';
import { AppAction, AppActionType, DrawerTab } from '@/store/modules/app/types';
import { DevicesAction, DevicesActionType } from '@/store/modules/devices/types';
import { Models } from '@mtap-smartcity/lib-api';
import {
  Component, Vue, PropSync, Prop
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const devices = namespace('devices');
const app = namespace('app');

@Component
export default class DeviceActionPopup extends Vue {
  @Prop(Object) readonly coordinates!: google.maps.LatLngLiteral;

  @PropSync('editDevice') syncedEditDevice!: Models.Devices.Device | null;

  @Prop(Boolean) permissionCheckAnalytics!: boolean;

  @Prop(Boolean) permissionCheckControl!: boolean;

  @Prop(Boolean) permissionCheckDevices!: boolean;

  @Prop(Boolean) isControlCabinet!: boolean;
  
  // store bindings
  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB']

  @app.Action(AppAction.SetSelectedDevicesTab)
  setSelectedDevicesTab!: AppActionType['SET_SELECTED_DEVICES_TAB']

  @app.Action(AppAction.SetSelectedAnalyticsTab)
  setSelectedAnalyticsTab!: AppActionType['SET_SELECTED_ANALYTICS_TAB']

  @devices.Action(DevicesAction.SetSelectedMarkerId)
  setSelectedMarkerId!: DevicesActionType['SET_SELECTED_MARKER_ID'];

  @devices.Action(DevicesAction.AddDevicePlaceholder)
  AddDevicePlaceholder!: DevicesActionType['ADD_DEVICE_PLACEHOLDER']

  @devices.Action(DevicesAction.SetSelectedId)
  setSelectedId!: DevicesActionType['SET_SELECTED_ID'];

  get deviceType() {
    if (!this.syncedEditDevice) return null;
    if (
      this.syncedEditDevice.device_type === 'gateway'
      && this.isControlCabinet
    ) return 'control-cabinet';
    return this.syncedEditDevice.device_type;
  }

  create(deviceType: 'lamp' | 'gateway') {
    this.AddDevicePlaceholder({
      type: deviceType,
      coordinates: this.coordinates,
    });
    this.setSelectedTab(DrawerTab.Devices);
    this.setSelectedDevicesTab(deviceType);
    this.$emit('close');
  }

  discard() {
    this.$emit('close');
  }

  showDeviceDetails() {
    if (this.syncedEditDevice) {
      this.setSelectedId(this.syncedEditDevice.id as number);
    }
    this.setSelectedTab(DrawerTab.Devices);
    this.setSelectedDevicesTab(this.deviceType!);
    this.syncedEditDevice = null;
    this.$emit('close');
  }

  showTelemetry() {
    if (this.syncedEditDevice) {
      this.setSelectedId(this.syncedEditDevice.id as number);
    }
    this.setSelectedTab(DrawerTab.Analytics);
    this.setSelectedAnalyticsTab(AnalyticsTabs.Telemetry);
    this.syncedEditDevice = null;
    this.$emit('close');
  }

  showLampControl() {
    if (this.syncedEditDevice) {
      this.setSelectedId(this.syncedEditDevice.id as number);
      this.setSelectedMarkerId(this.syncedEditDevice.id as number);
    }
    this.syncedEditDevice = null;
    this.$emit('close');
  }
}
