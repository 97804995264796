import { ActionTree } from 'vuex';
import { AdminState, AdminAction, CompetencesTableRow } from './types';
import { RootState } from '@/store/types';
import { userActionDispatcher } from '../app/actions';
import { AuthUsers, AuthGroups, AuthRoles } from '@/api/auth';
import { BffAuth } from '@/api/bff';
import { Models } from '@mtap-smartcity/lib-api';
import competencesFormObj, { CompetenceData, CompetenceType } from '@/constants/competencesFormObj';
// import competencesFormObj, { CompetenceData, CompetenceType } from '@/constants/competencesFormObj';
// import { KeycloakRole } from '@/types/users';

export const actions: ActionTree<AdminState, RootState> = {

  // async [AdminAction.FetchUsers]({ commit }): Promise<void> {
  //   const users = await AuthUsers.get();
  //   const modifiableUsers = users.filter((u) => u.username !== 'mtap' && u.username !== 'cypress');
  //   commit(`${AdminAction.FetchUsers}Handler`, modifiableUsers);
  // },

  async [AdminAction.FetchUsers]({ commit }): Promise<void> {
    const users = await BffAuth.BffAuthUsers.get();
    const modifiableUsers = users.filter((u) => u.username !== 'mtap' && u.username !== 'cypress');
    commit(`${AdminAction.FetchUsers}Handler`, modifiableUsers);
  },

  // async [AdminAction.FetchUser]({ commit }, payload: string): Promise<void> {
  //   const user = await AuthUsers.getId(payload);
  //   commit(`${AdminAction.FetchUser}Handler`, user);
  // },

  async [AdminAction.FetchUser]({ commit }, payload: string): Promise<void> {
    const user = await BffAuth.BffAuthUsers.getId(payload);
    commit(`${AdminAction.FetchUser}Handler`, user);
  },

  [AdminAction.AddUserPlaceholder]({ commit }): void {
    const placeholder = {
      id: null,
      username: '',
    };
    commit(`${AdminAction.AddUserPlaceholder}Handler`, placeholder);
  },

  [AdminAction.RemoveUserPlaceholder]({ commit }): void {
    commit(`${AdminAction.RemoveUserPlaceholder}Handler`);
  },
  // [AdminAction.SetSelectedUser]({ commit }, payload: Models.Bff.Auth.Users.Model | null): void {
  [AdminAction.SetSelectedUser]({ commit }, payload: Models.Bff.Auth.Users.Model | null): void {
    commit(`${AdminAction.SetSelectedUser}Handler`, payload);
  },

  // async [AdminAction.CreateUser]({ dispatch, commit }, user: Models.Bff.Auth.Users.Model): Promise<void> {
  async [AdminAction.CreateUser]({ dispatch, commit }, user: Models.Bff.Auth.Users.Model): Promise<void> {
    const newUser = await AuthUsers.post(user);
    commit(`${AdminAction.CreateUser}Handler`, newUser);
    dispatch(AdminAction.RemoveUserPlaceholder);
  },

  // async [AdminAction.UpdateUser]({ commit }, user: Models.Bff.Auth.Users.Model): Promise<void> {
  async [AdminAction.UpdateUser]({ commit }, user: Models.Bff.Auth.Users.Model): Promise<void> {
    const updatedUser = await AuthUsers.putId(user);
    commit(`${AdminAction.UpdateUser}Handler`, updatedUser);
  },

  async [AdminAction.DeleteUser]({ commit }, userId: string): Promise<void> {
    await AuthUsers.deleteId(userId);
    commit(`${AdminAction.DeleteUser}Handler`, userId);
  },

  async [AdminAction.ChangeUserPassword]({ commit }, user: Partial<Models.Bff.Auth.Users.Model>): Promise<void> {
    try {
      await AuthUsers.putPassword(user);
      commit(`${AdminAction.ChangeUserPassword}Handler`, user);
    } catch (e) {
      console.error(e);
    }
  },

  // [AdminAction.SetSelectedUser]({ commit }, payload: Models.Bff.Auth.Users.Model | null): void {
  [AdminAction.SetSelectedUser]({ commit }, payload: Models.Bff.Auth.Users.Model | null): void {
    commit(`${AdminAction.SetSelectedUser}Handler`, payload);
  },

  async [AdminAction.AddUserToGroup]({ commit, dispatch }, payload: Models.Auth.Groups.GroupsModel): Promise<void> {
    if (payload.userEnabled) {
      userActionDispatcher(dispatch, 'Adding user to the group', 'pending');
    } else {
      userActionDispatcher(dispatch, 'Removing user from the group', 'pending');
    }
    try {
      await AuthUsers.putIdModifyGroups(payload);
      userActionDispatcher(dispatch, '', 'success');
      commit(`${AdminAction.AddUserToGroup}Handler`, payload);
    } catch (error) {
      userActionDispatcher(dispatch, (this as any).$app.$t('messages.error'), 'error');
    }
  },

  async [AdminAction.FetchGroups]({ commit }): Promise<void> {
    const groups = await AuthGroups.get();
    commit(`${AdminAction.FetchGroups}Handler`, groups);
  },

  [AdminAction.AddGroupPlaceholder]({ commit }): void {
    const placeholder = {
      id: null,
      name: '',
    };
    commit(`${AdminAction.AddGroupPlaceholder}Handler`, placeholder);
  },

  [AdminAction.RemoveGroupPlaceholder]({ commit }): void {
    commit(`${AdminAction.RemoveGroupPlaceholder}Handler`);
  },
  [AdminAction.SetSelectedGroup]({ commit }, payload: Models.Auth.Groups.GroupsModel | null): void {
    commit(`${AdminAction.SetSelectedGroup}Handler`, payload);
  },
  // [AdminAction.CreateGroup]({ dispatch, commit }, group: UserGroup): Promise<void> {
  //   return createUserGroup(group)
  //     .then((newGroup) => {
  //       commit(`${AdminAction.CreateGroup}Handler`, newGroup);
  //     })
  //     .catch(() => {
  //       dispatch(AdminAction.RemoveGroupPlaceholder);
  //     });
  // },

  async [AdminAction.CreateGroup]({ commit }, group: Models.Auth.Groups.GroupsModel): Promise<void> {
    const createdGroup = await AuthGroups.post(group);
    commit(`${AdminAction.CreateGroup}Handler`, createdGroup);
  },

  async [AdminAction.UpdateGroup]({ commit }, group: Models.Auth.Groups.GroupsModel): Promise<void> {
    const createdGroup = await AuthGroups.putId(group);
    commit(`${AdminAction.UpdateGroup}Handler`, createdGroup);
  },

  async [AdminAction.DeleteGroup]({ commit }, groupId: string): Promise<void> {
    await AuthGroups.deleteId(groupId);
    commit(`${AdminAction.DeleteGroup}Handler`, groupId);
  },

  [AdminAction.SetSelectedGroup]({ commit }, payload: Models.Auth.Groups.GroupsModel | null): void {
    commit(`${AdminAction.SetSelectedGroup}Handler`, payload);
  },

  async [AdminAction.SetUserPermissions]({ dispatch }, roles: Models.Auth.Groups.GroupsModel[]): Promise<void> {
    const groupCompetences = await dispatch(AdminAction.GroupCompetences, roles);
    dispatch(AdminAction.Permissions, groupCompetences);
  },

  [AdminAction.Permissions]({ commit }, payload: [] | null): void {
    commit(`${AdminAction.Permissions}Handler`, payload);
  },

  [AdminAction.GroupCompetences](context, roles: Models.Auth.Roles.RolesModel[]): CompetencesTableRow[] {
    const groupCompetences: Record<CompetenceType, CompetenceData> = JSON.parse(JSON.stringify(competencesFormObj));
    roles.forEach(({ name }) => {
      const [type, permission] = name.split('_');
      groupCompetences[type][permission] = true;
    });
    const groupCompetencesArray = Object.entries(groupCompetences).map(([type, val]) => ({ type, ...val } as CompetencesTableRow));
    return groupCompetencesArray;
  },

  async [AdminAction.FetchAvailableRoles]({ commit }): Promise<void> {
    const roles = await AuthRoles.get();
    commit(`${AdminAction.FetchAvailableRoles}Handler`, roles);
  },

  async [AdminAction.FetchGroupRoles]({ commit }, groupId: string): Promise<void> {
    const groupRoles = await AuthGroups.getIdRole(groupId);
    commit(`${AdminAction.FetchGroupRoles}Handler`, { groupId, groupRoles });
  },

  async [AdminAction.ModifyGroupRoles]({ dispatch }, payload: {groupId: string, roles: Models.Auth.Roles.RolesModel[] }): Promise<void> {
    try {
      await AuthGroups.putIdModifyRoles(payload.groupId, payload.roles);
      dispatch(AdminAction.FetchGroupRoles, payload.groupId);
    } catch (error) {
      console.error(error);
    }
  },

  [AdminAction.SetCreateUserFlag]({ commit }): void {
    commit(`${AdminAction.SetCreateUserFlag}Handler`);
  },
};
