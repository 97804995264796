import { MutationTree } from 'vuex';
import {
  AppState, AppAction, UserActionStatus, DrawerTab
} from './types';
import { Models } from '@mtap-smartcity/lib-api';
import { AnalyticsTabsTypes, CreatorTabsTypes } from '@/constants/main_card_inner_tabs';
import { RuntimeConfig } from '@/types/config';

export const mutations: MutationTree<AppState> = {
  [`${AppAction.SetRuntimeConfig}Handler`](state, payload: RuntimeConfig) {
    state.runtimeConfig = payload;
  },
  [`${AppAction.SetUserActionStatus}Handler`](state, payload: UserActionStatus) {
    state.userActionStatus = payload;
  },
  [`${AppAction.SetTimeoutID}Handler`](state, payload: number) {
    state.timeoutID = payload;
  },
  [`${AppAction.SetSelectedTab}Handler`](state, tabName: DrawerTab) {
    state.selectedTab = tabName;
  },
  [`${AppAction.SetSelectedDevicesTab}Handler`](state, tab: Models.Constants.DeviceType) {
    state.selectedDevicesTab = tab;
  },
  [`${AppAction.SetSelectedAnalyticsTab}Handler`](state, tab: AnalyticsTabsTypes) {
    state.selectedAnalyticsTab = tab;
  },
  [`${AppAction.SetSelectedCreatorTab}Handler`](state, tab: CreatorTabsTypes) {
    state.selectedCreatorTab = tab;
  },
  [`${AppAction.SetMapSelectionEnabled}Handler`](state, payload: boolean) {
    state.mapSelectionEnabled = payload;
  },
  [`${AppAction.ToggleCircuitActionsCard}Handler`](state, payload: boolean) {
    state.showCircuitActionsCard = payload;
  },
  [`${AppAction.SetMapFocusPoint}Handler`](state, payload: { lat: number, lng: number }) {
    state.mapFocusPoint = payload;
  },
  [`${AppAction.SetTriggerHistoricals}Handler`](state, payload: boolean) {
    state.triggerHistoricals = payload;
  },
  [`${AppAction.SetTimeHistoricals}Handler`](state, payload: number) {
    state.timeHistoricals = payload;
  },
};
