import { GetterTree } from 'vuex';
import { CircuitsState, CircuitsGetter } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';

export const getters: GetterTree<CircuitsState, RootState> = {
  [CircuitsGetter.GetCircuit](state): (uuid: string) => Models.Circuits.Model | undefined {
    return (uuid: string) => state.circuits.find((circuit) => uuid && circuit.uuid === uuid);
  },

  [CircuitsGetter.GetCircuitById](state): (id: number) => Models.Circuits.Model | undefined {
    return (id: number) => state.circuits.find((circuit) => id && circuit.id === id);
  },

  [CircuitsGetter.GetGroupCircuits](state): (groupId: string) => Array<Models.Circuits.Model> {
    return (groupId) => state.circuits.filter((g) => g.group_id === groupId);
  }
};
