import { ActionTree } from 'vuex';
import { GraphsAction, GraphsState } from './types';
import { RootState } from '@/store/types';
import { Models } from '@mtap-smartcity/lib-api';
import {
  fetchGraphs, createGraph, updateGraph, deleteGraph
} from '@/api/graphs';

export const actions: ActionTree<GraphsState, RootState> = {
  [GraphsAction.FetchGraphs](
    { commit },
    { unixTime = null }: { unixTime: number | null } = { unixTime: null }
  ): Promise<void> {
    return fetchGraphs(unixTime)
      .then((response) => commit(`${GraphsAction.FetchGraphs}Handler`, response));
  },

  [GraphsAction.CreateGraph]({ commit }, graph: Models.Graphs.Model): Promise<Models.Graphs.Model | void> {
    return createGraph(graph)
      .then((item) => {
        commit(`${GraphsAction.CreateGraph}Handler`, item);
        return item;
      })
      .catch((err) => {
        console.error(`Error: creating new graph: ${err}`);
      });
  },

  [GraphsAction.UpdateGraph]({ commit }, graphData: Models.Graphs.Model): Promise<void> {
    return updateGraph(graphData)
      .then(() => {
        commit(`${GraphsAction.UpdateGraph}Handler`, graphData);
      })
      .catch((err) => {
        console.error(`Error: updating graph: ${err}`);
      });
  },

  [GraphsAction.DeleteGraph]({ commit }, uuid: string): Promise<void> {
    return deleteGraph(uuid)
      .then(() => {
        commit(`${GraphsAction.DeleteGraph}Handler`, uuid);
      })
      .catch((err) => {
        console.error(`Error deleting graph: ${err}`);
      });
  }
};
