import { Models } from '@mtap-smartcity/lib-api';

interface CircuitsState {
  circuits: Array<Models.Circuits.Model>;
  selectedCircuitID: number | null;
  selectedCircuitUuid: string | null;
}

enum CircuitsGetter {
  GetCircuit = 'GET_CIRCUIT',
  GetCircuitById = 'GET_CIRCUIT_BY_ID',
  GetGroupCircuits = 'GET_GROUP_CIRCUITS'
}

type CircuitsGetterType = {
  [CircuitsGetter.GetCircuit]: (uuid: string) => Models.Circuits.Model | undefined,
  [CircuitsGetter.GetCircuitById]: (id: number) => Models.Circuits.Model | undefined,
  [CircuitsGetter.GetGroupCircuits]: (uuid: string) => Array<Models.Circuits.Model>
}

enum CircuitsAction {
  FetchCircuits = 'FETCH_CIRCUITS',
  SetSelectedCircuitID = 'SET_SELECTED_CIRCUIT_ID',
  SetSelectedCircuitUuid = 'SET_SELECTED_CIRCUIT_UUID',
  AddCircuitPlaceholder = 'ADD_CIRCUIT_PLACEHOLDER',
  RemoveCircuitPlaceholder = 'REMOVE_CIRCUIT_PLACEHOLDER',
  CreateCircuit = 'CREATE_CIRCUIT',
  UpdateCircuit = 'UPDATE_CIRCUIT',
  DeleteCircuit = 'DELETE_CIRCUIT',
  AddCircuitToGroup = 'ADD_CIRCUIT_TO_GROUP',
  AddCircuitToScenario = 'ADD_CIRCUIT_TO_SCENARIO',
  OverwriteCircuitSettings = 'OVERWRITE_CIRCUIT_SETTINGS',
}

type CircuitsActionType = {
  [CircuitsAction.FetchCircuits]: (payload?: { unixTime?: number | null }) => Promise<void>,
  [CircuitsAction.SetSelectedCircuitID]: (id: number | null) => void,
  [CircuitsAction.SetSelectedCircuitUuid]: (uuid: string | null) => void,
  [CircuitsAction.AddCircuitPlaceholder]: () => void,
  [CircuitsAction.RemoveCircuitPlaceholder]: () => void,
  [CircuitsAction.CreateCircuit]: (circuit: Models.Circuits.Model) => Promise<void>,
  [CircuitsAction.UpdateCircuit]: (circuit: Models.Circuits.Model) => Promise<void>,
  [CircuitsAction.DeleteCircuit]: (uuid: string) => Promise<void>,
  [CircuitsAction.AddCircuitToGroup]: (payload: { uuid: string, groupId: string | null }) => Promise<void>,
  [CircuitsAction.AddCircuitToScenario]: ({
    circuit,
    scenarioName,
    assignToDefault
  }: {
    circuit: Models.Circuits.Model,
    scenarioName: string | null
    assignToDefault: boolean
  }) => Promise<void>,
  [CircuitsAction.OverwriteCircuitSettings]: (circuit: Models.Circuits.Model) => Promise<void>,
}

export {
  CircuitsState,
  CircuitsGetter,
  CircuitsGetterType,
  CircuitsAction,
  CircuitsActionType
};
